import { cloneElement, memo, useCallback } from 'react';
import {
  Typography,
  Box,
  Skeleton, Theme,
  ListItemButton,
} from '@mui/material';
import { NotificationData } from '../../../utils/notification';
import { makeStyles, createStyles } from '@mui/styles';
import { formatDistanceToNow } from 'date-fns';
import { it } from 'date-fns/esm/locale';
import { useReadNotification } from '../../../hooks/notification/useReadNotifications';
import { useNavigate } from 'react-router-dom';
import { getRoute, RoutesKeys } from '../../../routes';

type StyleProps = {
  read: boolean;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  listItem: {
    position: 'relative',
    '&:hover $notificationListItemMenu': {
      opacity: 1,
    },
  },
  notificationListItemMenu: {
    position: 'absolute',
    opacity: 0,
    transition: 'opacity background-color ease 0.2s',
    right: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  listItemButton: ({ read }: StyleProps) => ({
    backgroundColor: read ? theme.palette.background.default : 'white',
  }),
}));

export const SEARCH_APPOINTMENT_KEY = '@medic:search-appointment';
export const getSearchAppointmentKey = () => {
  return SEARCH_APPOINTMENT_KEY;
}
export interface NotificationWidgetItemProps {
  notification?: NotificationData;
  loading: boolean;
  onClose?: () => void;
}

export const NotificationItem = memo<NotificationWidgetItemProps>(({ notification, loading, onClose }) => {
  const read = notification?.readAt ? true : false;
  const classes = useStyles({ read });
  const data = notification;
  const navigate = useNavigate();

  const { onRead /*, loading: readLoading */ } = useReadNotification();

  const markAsRead = useCallback(() => {
    if (notification && !notification.readAt) {
      onRead(notification.id);
    }
  }, [notification, onRead]);

  const goToAppointment = useCallback(() => {
    if(data?.appointment?.id && data?.appointment?.day) {
      const appointmentId = data.appointment.id;
      const appointmentDate = data.appointment.day.split('/').reverse().join('-');
      const searchedAppointment = `${appointmentId},${appointmentDate}`;
      sessionStorage.setItem(getSearchAppointmentKey(), searchedAppointment);
      navigate(getRoute(RoutesKeys.agenda));
    }
  },[data, navigate])

  const onActionClick = useCallback(() => {
    markAsRead();
    if(data?.name === 'appointment-created-by-patient-to-professional' || data?.name === 'avails-appointment-created-by-patient-to-professional') {
      goToAppointment();
    } else if (data?.action?.url) {
      navigate(data.action.url);
      if (onClose) {
        onClose();
      }
    }
  }, [markAsRead, data, goToAppointment, navigate, onClose]);


  const icon = data?.icon ? cloneElement(data.icon as any, {
    fontSize: 'small',
    transform: 'scale(0.7)',
    fontWeight: 'bold',
    color: read
      ? data.readColor
        ? data.readColor
        : 'disabled'
      : 'primary',
  }) : <Skeleton variant="circular" width={24} height={24}/>;

  /* const availableActions = [];
  if (!read) {
    availableActions.push({
      label: t('mark-as-read'),
      icon: <DoneIcon/>,
      onClick: markAsRead,
    });
  }
  if (data?.action) {
    availableActions.push({
      label: data.action.text,
      icon: <Tab/>,
      onClick: onActionClick,
    });
  }*/

  if (!data) {
    return null;
  }

  return (<>
    <ListItemButton divider onClick={onActionClick} className={classes.listItemButton}>
      <Box display="flex" alignItems="center" width="100%">
        <Box pr={2}>
          {icon}
        </Box>
        <Box width="100%">
          <Typography variant="subtitle2" fontWeight={read ? '400' : '600'}>
            {data?.title}
          </Typography>
          <Typography variant="body2" color="textSecondary">{data?.message}</Typography>
          <Box display="flex" justifyContent="end">
            {data?.createdAt && (
              <Typography variant="subtitle2" fontWeight={read ? '400' : '600'}>
                {
                  formatDistanceToNow(data.createdAt, {
                    locale: it,
                    addSuffix: true,
                  })
                }
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </ListItemButton>
    {/* <ListItem divider={divider} dense className={classes.listItem}>
      {!isMobile && (
        <ListItemIcon className={classes.listItemIcon}>
          {icon}
        </ListItemIcon>
      )}
      <ListItemText
        disableTypography
        primary={loading ? (
          <Skeleton width={120}/>
        ) : (
          <Typography variant="body2" className={clsx({
            [classes.listItemTextUnread]: !read,
          })}>{data?.title}</Typography>
        )}
        secondary={loading ? <Skeleton width={250}/> : (
          <>
            <Typography variant="body2" color="textSecondary">{data?.message}</Typography>
            <Box flexBasis="100%" display="flex" justifyContent="space-between">
              {data?.action && (
                <Link
                  variant="body2"
                  className={clsx({
                    [classes.listItemTextUnread]: !read,
                  }, classes.listItemActionLink)}
                  onClick={onActionClick}
                  color="primary"
                >
                  {data.action.text}
                </Link>
              )}
              {data?.createdAt && (
                <Typography variant="subtitle2" component="span">
                  {' '}{formatDistanceToNow(data.createdAt, {
                  locale: it,
                  addSuffix: true,
                })}
                </Typography>
              )}
            </Box>
          </>
        )}
      />
      {(notification && availableActions.length > 0) && (
        <>
          <Fab
            size="small"
            className={classes.notificationListItemMenu}
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            <MoreHoriz/>
          </Fab>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            {availableActions.map(action => (
              <MenuItem
                key={action.label}
                onClick={action.onClick}
                dense
                disabled={readLoading}
              >
                <ListItemIcon style={{ minWidth: 36 }}>
                  {action.icon}
                </ListItemIcon>
                <Typography variant="inherit">{action.label}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </ListItem> */}
    </>
  );
});

import { NotificationDataFragment } from '../generated/graphql';
import { TFunction } from 'i18next';
import { ReactNode } from 'react';
import { DoctorIcon, NetworkIcon, RequestsIcon, ReportsIcon } from './icons';
import { parseISO } from 'date-fns';

import { Chat, Today, GroupWork } from '@mui/icons-material';
import { CustomIcon } from '../views/components/custom-icon';
import EmailIcon from '@mui/icons-material/Email';
import Diversity3Icon from '@mui/icons-material/Diversity3';
export interface NotificationData {
  id: string;
  title: string;
  name: string;
  message?: string;
  createdAt?: Date;
  readAt?: string;
  icon: ReactNode;
  action?: {
    url: string;
    text: string;
  }
  readColor?: string;
  appointment?: {
    id: string;
    day: string;
    from: string;
    clinicName: string;
  }
}

function getNotificationUri(url: string) {
  const uri = new URL(url);
  return uri.pathname + uri.search;
}

export function notificationData(notification: NotificationDataFragment, t: TFunction) {
  const commonData = {
    id: notification.id,
    readAt: notification.readAt,
    createdAt: parseISO(notification.createdAt),
    name: notification.name,
  };
  switch (notification.name) {
    case 'empty':
      return {
        id: '',
        title: t('no-notifications'),
      }
    case 'network-requested':
      return {
        ...commonData,
        action: {
          url: getNotificationUri(notification.data.action.url),
          text: notification.data.action.text,
        },
        title: t('notification:new-network-request'),
        message: t('notification:new-network-request-message', {
          theRole: notification.data.sender.theRole,
          fullName: notification.data.sender.fullName,
        }),
        icon: <NetworkIcon/>,
      }
    case 'network-accepted':
      return {
        ...commonData,
        title: t('notification:network-request-accepted'),
        message: t('notification:network-request-accepted-message', {
          theRole: notification.data.sender.theRole,
          fullName: notification.data.sender.fullName,
        }),
        icon: <NetworkIcon/>,
      }
    case 'network-deleted':
      return {
        ...commonData,
        title: t('notification:network-request-deleted'),
        message: t('notification:network-request-deleted-message', {
          role: notification.data.recipient.role,
          fullName: notification.data.sender.fullName,
        }),
        icon: <NetworkIcon />,
      }
    case 'request-created':
      return {
        ...commonData,
        action: {
          url: getNotificationUri(notification.data.action.url),
          text: notification.data.action.text,
        },
        title: notification.data.subject,
        message: t('notification:request-created-message', {
          theRole: notification.data.sender.role,
          fullName: notification.data.sender.fullName,
        }),
        icon: <RequestsIcon/>,
      }
    case 'request-new-message':
      return {
        ...commonData,
        action: {
          url: getNotificationUri(notification.data.action.url),
          text: notification.data.action.text,
        },
        title: notification.data.subject,
        message: t('notification:request-new-message-message', {
          theRole: notification.data.author.theRole || 'the_patient',
          fullName: notification.data.author.fullName,
        }),
        icon: <RequestsIcon/>,
      }
    case 'request-resolved':
      console.log(notification)
      return {
        ...commonData,
        action: {
          url: getNotificationUri(notification.data.action.url),
          text: notification.data.action.text,
        },
        title: t('notification:request-resolved.title'),
        message: t('notification:request-resolved.message', {
          theRole: notification.data.sender.theRole || 'the_patient',
          fullName: notification.data.sender.fullName,
          //title: notification.data.request.title,
          title: notification.data.request
            ? notification.data.request.title
            : notification.data.requestTitle,
        }),
        icon: <RequestsIcon/>,
      }
    case 'agenda-assigned':
      return {
        ...commonData,
        title: t('notification:agenda-assigned'),
        message: notification.data.subject,
        icon: <Today/>,
      }
    case 'appointment-created-by-patient-to-professional':
      return {
        ...commonData,
        title: t('notification:appointment-created-by-patient.title'),
        message: t('notification:appointment-created-by-patient.message', {
          patientName: notification.data.patientName,
          appointment: notification.data.appointment,
        }),
        icon: <Today/>,
        appointment: notification.data.appointment,
       /*  action: notification.data.action ? {
          url: getNotificationUri(notification.data.action.url),
          text: notification.data.action.text,
        } : undefined, */
      }
    case 'avails-appointment-created-by-patient-to-professional':
      return {
        ...commonData,
        title: t('notification:appointment-created-by-patient.title'),
        message: t('notification:appointment-created-by-patient.message', {
          patientName: notification.data.patientName,
          appointment: notification.data.appointment,
        }),
        icon: <Today/>,
        appointment: notification.data.appointment,
        /* action: notification.data.action ? {
          url: getNotificationUri(notification.data.action.url),
          text: notification.data.action.text,
        } : undefined, */
      }
    case 'appointment-deleted-by-patient-to-professional':
      return {
        ...commonData,
        title: t('notification:appointment-deleted-by-patient.title'),
        message: t('notification:appointment-deleted-by-patient.message', {
          patientName: notification.data.patientName,
          appointment: notification.data.appointment,
        }),
        icon: <Today/>,
      }
    case 'avails-appointment-deleted-by-patient-to-professional':
      return {
        ...commonData,
        title: t('notification:appointment-deleted-by-patient.title'),
        message: t('notification:appointment-deleted-by-patient.message', {
          patientName: notification.data.patientName,
          appointment: notification.data.appointment,
        }),
        icon: <Today/>,
      }
    case 'chat-new-message':
      const { search: newMessageUrl } = new URL(notification.data.action.url);
      return {
        ...commonData,
        title: notification.data.subject,
        icon: <Chat/>,
        action: {
          url: newMessageUrl,
          text: notification.data.action.text,
        },
      }
    case 'chat-new-message-mail':
      const { search: newMessageMailUrl } = new URL(notification.data.action.url);
      return {
        ...commonData,
        title: notification.data.subject,
        icon: <EmailIcon/>,
        action: {
          url: newMessageMailUrl,
          text: notification.data.action.text,
        },
      }
    case 'patient-answer-to-invite-in-group-medicine':
      return {
        ...commonData,
        title: notification.data.subject,
        icon: <GroupWork />,
        action: {
          url: getNotificationUri(notification.data.action.url),
          text: notification.data.action.text,
        },
      }
    case 'personal-medic-transfer-created':
      return {
        ...commonData,
        title: notification.data.subject,
        icon: <CustomIcon Icon={DoctorIcon} />,
        // eslint-disable-next-line max-len
        message: `Il paziente ${notification.data.sender.surname || ''} ${notification.data.sender.name || ''} desidera associarsi al tuo profilo Medico`,
        action: {
          url: getNotificationUri(notification.data.action.url),
          text: notification.data.action.text,
        },
        readColor: '#B6B7BB',
      }
    case 'professional-new-patient-requested':
      const patient = notification.data.sender;
      const recipient = notification.data.recipient;
      return {
        ...commonData,
        title: 'Richiesta associazione', // FIXME notification subject broken
        icon: <NetworkIcon/>,
        message: `Il paziente ${patient.surname || ''} ${patient.name || ''} desidera associarsi al tuo profilo ${t(recipient.role)}`,
        action: {
          url: getNotificationUri(notification.data.action.url),
          text: notification.data.action.text,
        },
      }
    case 'report-shared':
      return {
        ...commonData,
        title: notification.data.subject,
        icon: <ReportsIcon />,
        action: {
          url: getNotificationUri(notification.data.action.url),
          text: notification.data.action.text,
        },
      }
    case 'invite-assistant-invited':
      return {
        ...commonData,
        title: notification.data.subject,
        icon: <Diversity3Icon />,
        action: {
          url: getNotificationUri(notification.data.action.url),
          text: notification.data.action.text,
        },
      }
  }
}

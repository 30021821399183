import { useQuery } from '@apollo/client';
import {
  GetNotificationsDocument,
  GetNotificationsQuery,
  GetNotificationsQueryVariables,
  OnNewNotificationDocument, OnNewNotificationSubscription, OnNewNotificationSubscriptionVariables,
} from '../../generated/graphql';
import { useCallback, useMemo } from 'react';
import { useProfile } from '../../contexts';
import { NotificationData, notificationData } from '../../utils/notification';
import { useTranslation } from 'react-i18next';

interface UseNotificationsProps {
  onlyUnread?: boolean;
  skip?: number;
  limit?: number;
  onlyTypes?: string[];
}

export const useNotifications = ({ onlyUnread = false, skip = 0, limit = 10, onlyTypes }: UseNotificationsProps = {})  => {
  const { actingAs } = useProfile();
  const { t } = useTranslation();
  const filters: GetNotificationsQueryVariables['filters'] = {};
  if (onlyUnread) {
    filters.readAt = { is_null: true };
  }
  if (onlyTypes) {
    filters.name = { in: onlyTypes };
  }

  const { data, subscribeToMore, ...rest } = useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, {
    variables: {
      filters,
      skip,
      limit,
      read: onlyUnread ? false : undefined,
    },
    context: {
      batch: false,
    },
    fetchPolicy: 'network-only',
  });

  const subscribe = useCallback(() => {
    // https://github.com/apollographql/apollo-client/issues/5870
    if (process.env.NODE_ENV === 'development') {
      return;
    }
    subscribeToMore<OnNewNotificationSubscription, OnNewNotificationSubscriptionVariables>({
      document: OnNewNotificationDocument,
      updateQuery: (previousQueryResult, { subscriptionData }) => {
        if (!subscriptionData.data?.onNewNotification) {
          return previousQueryResult;
        }
        const item = subscriptionData.data.onNewNotification;

        return {
          ...previousQueryResult,
          notifications: {
            items: [
              item,
              ...previousQueryResult.getNotifications.items,
            ],
            paginationInfo: previousQueryResult.getNotifications.paginationInfo,
          },
        }
      },
    });
  }, [subscribeToMore]);

  const notifications = useMemo(() => {
    return data?.getNotifications.items.filter(not => {
      const destination = not.destinationProfile;
      if (!destination) return true;
      const [role, id] = destination.split(':');
      return actingAs?.role === role && actingAs?.id.toString() === id;
    });
  }, [data, actingAs]);

  const notificationsToShow = useMemo<NotificationData[]>(() => (
    notifications || []).map(n => notificationData(n, t)).filter(Boolean) as NotificationData[],
  [notifications, t]);

  return useMemo(() => ({
    notifications,
    notificationsToShow,
    paginationInfo: data?.getNotifications.paginationInfo,
    totalUnreadNotifications: data?.getNotifications.totalUnReadNotification,
    subscribe,
    ...rest,
  }), [
    data,
    rest,
    subscribe,
    notifications,
    notificationsToShow,
  ]);
}
